import { isWeakMap } from "lodash";
import React from "react";

const DEFAULT_TIMEOUT = 5 * 60 * 1000; // 5min * 60s * 1000millis
const DEFAULT_WARNING_PERIOD = 1 * 60 * 10000;

class InactivityTracker extends React.Component {
	static defaultProps = {
		timeout: DEFAULT_TIMEOUT,
		warningTimeout: DEFAULT_WARNING_PERIOD,
		onInactive: () => { },
		onWarning: () => { },
	};

	constructor(props) {
		super(props);
		this.state = {
			isInactive: false,
			isWarning: false,
			inactivityDuration: 0
		};
		this.activityTimeout = null;
		this.warningTimeout = null;
		this.inactivityTimerInterval = null;
		this.inactivityStart = null;
		this.handleActivity = this.handleActivity.bind(this);
	}

	componentDidMount() {
		this.inactivityStart = Date.now();

		const events = ["mousemove", "keydown", "scroll", "click"];
		events.forEach((event) =>
			window.addEventListener(event, this.handleActivity)
		);

		this.syncWithLocalStorage();
		this.resetTimers();
		this.resetInactivityTimer();

		// Listen for localStorage changes across tabs
		window.addEventListener("storage", this.handleStorageChange.bind(this));
	}

	componentWillUnmount() {
		const events = ["mousemove", "keydown", "scroll", "click"];
		events.forEach((event) =>
			window.removeEventListener(event, this.handleActivity)
		);
		this.clearTimers();
		window.removeEventListener("storage", this.handleStorageChange.bind(this));
	}

	handleActivity() {
		this.resetTimers();

		if (this.state.isInactive) {
			this.setState({ isInactive: false, inactivityDuration: 0 }, this.updateLocalStorage);
		}
		if (this.state.isWarning) {
			this.setState({ isWarning: false });
		}

		this.inactivityStart = Date.now();
		this.resetInactivityTimer();
	}

	resetTimers() {
		const { timeout, warningTimeout, onInactive, onWarning } = this.props;

		this.clearTimers();
		// this.inactivityStart = Date.now();
		// this.startInactivityTimer();

		this.warningTimeout = setTimeout(() => {
			this.setState({ isWarning: true });
			onWarning();
		}, timeout - warningTimeout);

		this.activityTimeout = setTimeout(() => {
			this.setState({ isInactive: true, isWarning: false }, () => {
				this.updateLocalStorage();
				onInactive();
				clearInterval(this.inactivityTimerInterval);
			});
		}, timeout);
	}

	startInactivityTimer() {
		this.inactivityTimerInterval = setInterval(() => {
			const now = Date.now();

			if (this.state.isInactive) {
				this.setState({ inactivityDuration: 0, isWarning: false });
			} else {
				const inactivityDuration = now - this.inactivityStart;
				this.setState({ inactivityDuration });
			}
		}, 100);
	}

	resetInactivityTimer() {
		clearInterval(this.inactivityTimerInterval);
		this.startInactivityTimer();
	}

	clearTimers() {
		clearTimeout(this.activityTimeout);
		clearTimeout(this.warningTimeout);
	}

	syncWithLocalStorage() {
		const isInactive = localStorage.getItem("userInactive") === "true";

		if (isInactive) {
			this.setState({
				isInactive: true,
				isWarning: false
			});
		}

		const inactivityStart = parseInt(
			localStorage.getItem("inactivityStart") || Date.now(),
			10
		);
		this.inactivityStart = inactivityStart;

		this.setState(
			{
				inactivityDuration: Date.now() - inactivityStart,
				isWarning: (Date.now() - inactivityStart) >= (this.props.timeout - this.props.warningTimeout)
			},
			this.startInactivityTimer
		);
	}

	updateLocalStorage() {
    localStorage.setItem("userInactive", this.state.isInactive.toString());
    if (this.state.isInactive) {
      localStorage.removeItem("inactivityStart");
		} else {
      localStorage.setItem("inactivityStart", this.inactivityStart.toString());
    }
  }

	handleStorageChange(event) {
    if (event.key === "userInactive") {
      const isInactive = event.newValue === "true";
      if (isInactive !== this.state.isInactive) {
        this.setState({ isInactive });
      }
    }

    if (event.key === "inactivityStart") { // && this.state.isInactive
      const inactivityStart = parseInt(event.newValue, 10);
      this.inactivityStart = inactivityStart;
      this.setState({
        inactivityDuration: Date.now() - inactivityStart,
      });
    }
  }

	render() {
		const { timeout } = this.props;
		const { isInactive, isWarning, inactivityDuration } = this.state;

		return this.props.children({
			isInactive,
			isWarning,
			inactivityDuration: (timeout / 1000).toFixed() - (inactivityDuration / 1000).toFixed()
		});
	}
}

export default InactivityTracker;
